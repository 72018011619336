<template>
    <div class="swiper-content course">
        <div class="self-img">
            <img :src="courseInfo.describeUrl" alt="">
        </div>
        <div class="venue">
            <div class="venue-title row-between-center">
                <div class="">新人推荐课程</div>
                <div></div>
            </div>
            <div class="course-list">
                <div class="course-item" v-for="(item, index) in courseList" :key="index">
                    <img :src="item.picUrl" alt="" @click="jumpToAboutClass(item.topicId, item.title)">
<!--                    <div class="course-item-t row-start-center" @click="jumpToAboutClass(item.topicId, item.title)">-->
<!--                        <img :src="item.picUrl" alt="">-->
<!--                        <div>-->
<!--                            <div class="course-name1 ellipsis">{{ item.title }}</div>-->
<!--                            <p class="course-name2">{{ item.courseTime }}</p>-->
<!--                            <p class="course-name3">{{ item.kcal }}</p>-->
<!--                            <p class="course-name4">{{ item.people }}</p>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="course-item-b">-->
<!--                        <p>{{ item.tips }}</p>-->
<!--                        <span>TIPS</span>-->
<!--                    </div>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import userMixin from '@/mixin/userMixin';
import wx from "weixin-js-sdk";
export default {
    props:{
        courseInfo: {
            type: Object,
            default() {
                return {}
            }
        }
        
    },
    mixins: [userMixin],
    data() {
        return {
            courseList: [],
            currentCityId: '3'
        }
    },
    async created() {
        await this.$getUserId();
        await this.$getCityId();
        this.currentCityId = await this.$getCityId();
        this.getScheduleRecommend()
    },
    methods: {
        getScheduleRecommend() {
            this.$axios.post(`${this.baseURLApp}/app/newcomerPower/scheduleRecommend`, {
                cityId: this.currentCityId || '3',
                userId: this.userId
            }).then(res => {
                this.courseList = res.data
            })
        },
        jumpToAboutClass(topicId, name){
            if (this.appTypeStr === 'mini'){
                wx.miniProgram.navigateTo({
                    url: `/pages/webView/index?webUrl=${this.locationUrl}/#/selfGroupClass&userId=1&location=1&cityId=1&topicId=${topicId}&title= ${name}`
                })
            }else{
                this.$router.push({ path: '/selfGroupClass', query: { topicId: topicId, title: name, isBackToH5: 'true'}})
            }
        },
    }
}
</script>

<style scoped lang="less">
.swiper-content{
    padding: 28px;
    box-sizing: border-box;
    width: 100%;
    background-color: white;
    border-radius: 16px;
    position: relative;
    // &:after{
    //     content: '';
    //     width: 21px;
    //     height: 21px;
    //     background-color: white;
    //     position: absolute;
    //     top: -10px;
    //     left: 94px;
    //     transform: rotateZ(45deg);
    // }
    .self-img{
        margin-bottom: 60px;
        img{
            width: 100%;
            border-radius: 16px;
        }
    }
    .venue-title{
        color: #242831;
        font-weight: bold;
        font-size: 28px;
        &>div:last-child{
            padding-right: 26px;
            color: #6C727A;
            font-weight: normal;
            font-size: 24px;
            background: url("https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/WeChat/defaultPic/right_gray.png") no-repeat right center;
            background-size: 20px 20px;
        }
        margin-bottom: 40px;
    }
    &.course{
        &:after{
            left: 328px!important;
        }
    }
    .course-list{
        .course-item{
            width: 630px;
            box-sizing: border-box;
            &>img{
                width: 100%;
            }
            &.course-item + .course-item{
                margin-top: 24px;
            }
        }
        .course-item-t{
            background: url("https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/new-people-2022/bg4.png") no-repeat center center;
            background-size: 100% 100%;
            padding: 36px 24px;
            img{
                width: 206px;
                height: 200px;
                border-radius: 12px;
            }
            &>div{
                flex-grow: 0;
                margin-left: 32px;
                .course-name1{
                    max-width: 344px;
                    font-family: BebasNeueBold;
                    font-size: 32px;
                    font-weight: bold;
                    color: #242831;
                }
                p{
                    color: #242831;
                    line-height: 34px;
                    font-weight: 500;
                    font-size: 24px;
                    background: url("https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/new-people-2022/icon1.png") no-repeat left center;
                    background-size: 26px 26px;
                    padding-left: 36px;
                    margin-top: 20px;
                    &.course-name2{
                        background-image: url("https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/new-people-2022/icon2.png");
                    }
                    &.course-name3{
                        background-image: url("https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/new-people-2022/icon3.png");
                    }
                    &.course-name4{
                        background-image: url("https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/new-people-2022/icon4.png");
                    }
                }
            }
        }
        .course-item-b{
            width: 630px;
            background: #F5F5F5;
            border-radius: 16px;
            padding: 48px 24px 32px;
            position: relative;
            margin-top: 24px;
            box-sizing: border-box;
            p{
                line-height: 32px;
                color: #3C454E;
                font-size: 22px;
                word-break: break-all;
            }
            span{
                position: absolute;
                left: 0;
                top: 0;
                width: 94px;
                height: 40px;
                background: #FF6E00;
                border-radius: 16px 0 16px 0;
                font-size: 24px;
                font-weight: bold;
                color: #FFFFFF;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
</style>
