<template>
    <div class="swiper-content">
        <div class="self-img">
            <img :src="selfInfo.describeUrl" alt="">
        </div>
        <div class="venue">
            <div class="venue-title row-between-center">
                <div class="">附近门店</div>
                <div @click="jumpVenue(1)" class="">查看更多</div>
            </div>
            <div class="venue-list no-swiping">
                <div @click="jumpVenue(2, item.id)" class="venue-item" v-for="(item, index) in venueList" :key="index">
                    <div class="venue-img" :style="{backgroundImage: `url(${dealUrl(item.facadeImgList[0])})`}">
                        <div v-if="item.distanceStr">距您{{ (item.distance < 100)? '小于100m' :item.distanceStr }}</div>
                    </div>
                    <div class="venue-name">{{ item.title }}</div>
                    <div class="open-time">营业中 | 07:00-23:00</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {gotoVenue} from '@/lib/appMethod';
export default {
    props:{
        selfInfo: {
            type: Object,
            default() {
                return {}
            }
        },
        venueList: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return{
        
        }
    },
    created() {
    
    },
    methods: {
        jumpVenue(type, id) {
            gotoVenue(type, id)
        },
        dealUrl(v){
            if (!v || v.indexOf('http') < 0) {
                return ('http://img.chaolu.com.cn'+v);
            } else {
                return v;
            }
        },
    }
    
}
</script>

<style scoped lang="less">
.swiper-content{
    padding: 28px;
    box-sizing: border-box;
    width: 100%;
    background-color: white;
    border-radius: 16px;
    position: relative;
    // &:after{
    //     content: '';
    //     width: 21px;
    //     height: 21px;
    //     background-color: white;
    //     position: absolute;
    //     top: -10px;
    //     left: 94px;
    //     transform: rotateZ(45deg);
    // }
    .self-img{
        margin-bottom: 60px;
        img{
            width: 100%;
            border-radius: 16px;
        }
    }
    .venue{
        overflow: hidden;
        .venue-title{
            color: #242831;
            font-weight: bold;
            font-size: 28px;
            &>div:last-child{
                padding-right: 26px;
                color: #6C727A;
                font-weight: normal;
                font-size: 24px;
                background: url("https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/WeChat/defaultPic/right_gray.png") no-repeat right center;
                background-size: 20px 20px;
            }
            margin-bottom: 40px;
        }
        .venue-list{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            overflow-y: auto;
            &::-webkit-scrollbar{
                display: none;
            }
            .venue-item{
                width: 264px;
                &.venue-item + .venue-item{
                    margin-left: 16px;
                }
                .venue-name{
                    line-height: 34px;
                    font-size: 24px;
                    color: #232831;
                    background: url("https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/WeChat/defaultPic/right_gray.png") no-repeat right center;
                    background-size: 24px 24px;
                    padding-right: 26px;
                    margin-top: 18px;
                }
                .venue-img{
                    width: 264px;
                    height: 136px;
                    background: url("https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/camp202201/swipe-item4.jpg") no-repeat center center;
                    background-size: cover;
                    border-radius: 8px;
                    overflow: hidden;
                    div{
                        width: 264px;
                        height: 136px;
                        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 36%, rgba(0, 0, 0, 0.6) 100%);
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-end;
                        color: white;
                        font-size: 22px;
                        line-height: 22px;
                        padding: 12px 20px;
                        box-sizing: border-box;
                    }
                }
                .open-time{
                    margin-top: 12px;
                    font-size: 22px;
                    line-height: 22px;
                    color: #6C727A;
                }
            }
        }
        
    }
}
</style>
