<template>
    <div class="page-rooter-fff">
        <nav-bar header-title="新人礼包" backColor="#ffffff" :show-back="true" :header-back="newAppBack"/>
        <app-page id="box" class="page-img">
            <div class="my-swipe">
                <img src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/new-people-2022/top_bg.png" alt="">
                <img class="customer" @click="showRule = true"
                     src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/new-people-2022/rule_open2.png"
                     alt="">
                <div v-show="coachHeader" @click.stop="jumpEvaluate()" class="coach-header col-center-center">
                    <img :src="coachHeader" alt="">
                    <p class="row-center-center">评价服务</p>
                </div>
            </div>
            <div class="tab" v-if="showType === '1'">
                <div v-for="(item, index) in infoList" class="flex-none" :style="`background-image: url(${item.minPic})`" :key="index" :class="{active: active == index}" @click="tabChange(index)">
                    <span>{{item.name}}</span>
                    <p v-if="item.voucherOverdueTime">有效期至 <br> {{ item.voucherOverdueTime }}</p>
                    <img v-if="item.receive === '0'" src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/new-people-2022/status5.png" alt="">
                    <img v-if="item.receive === '1'" src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/new-people-2022/status1.png" alt="">
                    <img v-if="item.receive === '2'" src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/new-people-2022/status2.png" alt="">
                    <img v-if="item.receive === '3'" src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/new-people-2022/status3.png" alt="">
                </div>
            </div>
            <div class="example-3d">
                <swiper v-if="selfInfo.describeUrl" ref="mySwiper" class="swiper" @slideChange="slideChange" :options="swiperOption">
                    <swiper-slide class="slide-1">
                        <self :venueList="venueList" :selfInfo="selfInfo"></self>
                    </swiper-slide>
                    <swiper-slide class="slide-2">
                        <course :courseInfo="courseInfo"></course>
                    </swiper-slide>
                    <swiper-slide class="slide-3">
                        <div class="swiper-content coach">
                            <div class="self-img">
                                <img :src="coachInfo.describeUrl" alt="">
                            </div>
                            <div class="coach-box">
                                <div class="coach-title">推荐教练</div>
                                <div class="select-venue row-between-center">
                                    <div  @click="storeShow = true" :class="{active: storeShow}">
                                        <span>{{ venueName }}</span>
                                        <img src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/new-people-2022/arrow_bottom.png" alt="">
                                    </div>
                                    <div class="" @click="jumpCoachList">查看更多</div>
                                </div>
                                <div class="coach-list no-swiping" v-if="coachList.length">
                                    <div @click="jumpCoachDetail(item.teacherId)" class="coach-item" v-for="(item, index) in coachList" :key="index">
                                        <img :src="dealUrl(item.halfImg)" alt="" @error="errorFun">
                                        <div class="coach-name"><div>{{ item.name }}</div><span>PT{{ item.rating }}</span></div>
                                        <div class="coach-tab" v-if="item.labelList.length">
                                            <span v-for="(v) in item.labelList" :key="v" class="ellipsis">{{ v }}</span>
                                        </div>
                                        <div class="coach-num">累计上课{{ item.classNum }}节</div>
                                        <div class="coach-price">
                                            <div>
                                                <span class="s1">¥</span>
                                                <span class="s2">{{ item.priceType === '1' ? item.price : item.firstTeacherPrice }}</span>
                                                <span class="s3">/节</span>
                                            </div>
                                            <p v-if="(item.priceType === '1' && item.discountLabel) || item.priceType!=='1'" class="ellipsis">{{ item.priceType === '1' ? item.discountLabel : '首节体验特惠' }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div v-else class="empty">
                                    <img src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/WeChat/defaultPic/empty1.png" alt="">
                                    <div>鹿团团正在努力排课中 <br> 亲爱的超鹿会员请稍等</div>
                                </div>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide v-if="campInfo" class="slide-4">
                        <div class="swiper-content coach">
                            <div class="self-img">
                                <img :src="campInfo.describeUrl" alt="">
                            </div>
                            <div class="coach-box">
                                <div class="coach-title">推荐小班课课程</div>

                                <div class="camp-list" v-if="campList.length">
                                    <div @click="jumpCamp(item.topicId)" class="camp-item" v-for="(item, index) in campList" :key="index">
                                        <img :src="item.picUrl" alt="">
                                    </div>
                                </div>
                                <div v-else class="empty">
                                    <img src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/WeChat/defaultPic/empty1.png" alt="">
                                    <div>鹿团团正在努力排课中 <br> 亲爱的超鹿会员请稍等</div>
                                </div>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
        </app-page>
        <div v-if="infoList[active].receive==='0'" class="bottom-btn row-center-center" @click="getCardInfo">点击解锁</div>
        <div v-else-if="infoList[active].receive==='1'" class="bottom-btn row-center-center" @click="getCardInfo">去使用</div>
        <div v-else-if="infoList[active].receive==='2'" class="bottom-btn row-center-center disabled">已使用</div>
        <div v-else-if="infoList[active].receive==='3'" class="bottom-btn row-center-center disabled">已过期</div>

        <div v-if="!userId" class="bottom-btn row-center-center" @click="toLoginPage">去登录解锁新人权益</div>



        <cl-city-venue2 :data="storeList" :popShow.sync="storeShow" @popfun="choseStorePop" type='venue'
                        :chose-item="choseItem" :single="true"></cl-city-venue2>

        <!--弹窗-->
        <van-popup v-model="showReward" :overlay="true" style="background-color: transparent"
                   :close-on-click-overlay="true">
            <div class="reward-box-o">
                <div class="reward-box">
                    <img class="popup-icon" src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/new-people-2022/popup_bg1.png" alt="">
                    <div class="reward-tips">{{boonInfo.subTitle}}</div>
                    <div class="boon-box">
                        <div>
                            <div>
                                <span>¥</span>
                                <p>{{ boonInfo.price }}</p>
                            </div>
                        </div>
                        <div>
                            <div>{{boonInfo.voucherName}}</div>
                            <p>领取后{{boonInfo.voucherEffectiveDays}}天有效</p>
                        </div>
                    </div>
                    <div class="boon-btn">
                        <div v-if="boonInfo.giftType == 5">
                            <img v-show="boonInfo.hasHealthRecord == 0 && boonInfo.directlyGet === '1'" @click="write(campInfo.userNewcomerPowerRegularId)" class="img1" src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/new-people-2022/popup_btn6.png" alt="">
                            <img v-show="boonInfo.hasHealthRecord == 0 && boonInfo.directlyGet === '1'" @click="unlock(boonInfo.giftType)" class="img2" src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/new-people-2022/popup_btn2.png" alt="">
                            <img v-show="boonInfo.hasHealthRecord == 0 && boonInfo.directlyGet === '0'" @click="write(campInfo.userNewcomerPowerRegularId)" class="img-big" src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/new-people-2022/popup_btn7.png" alt="">
                            <img v-show="boonInfo.hasHealthRecord == 1" @click="unlockCamp" class="img-big" src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/new-people-2022/popup_btn8.png" alt="">
                            <div v-show="boonInfo.directlyGet === '1'" class="directlyNum">可选择{{ boonInfo.directlyNum }}个权益直接解锁</div>
                        </div>
                        <div v-else-if="boonInfo.directlyGet === '0'">
                            <img @click="share(boonInfo.giftType)" class="img-big" src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/new-people-2022/popup_btn3.png" alt="">
                        </div>
                        <div v-else>
                            <img @click="share(boonInfo.giftType)" class="img1" src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/new-people-2022/popup_btn1.png" alt="">
                            <img @click="unlock(boonInfo.giftType)" class="img2" src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/new-people-2022/popup_btn2.png" alt="">
                            <div class="directlyNum">可选择{{ boonInfo.directlyNum }}个权益直接解锁</div>
                        </div>
                    </div>
                </div>
            </div>

            <img @click="showReward = false" class="result-close"
                 src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/double11-2021/result-close.png"
                 alt="">
        </van-popup>


        <!--  分享配置  -->
        <common-share
            :value="showShare"
            :shareParams="shareParams"
            @close="shareSuccess"
            @share-success="shareSuccess"
            :shareItem="shareItem"
            :miniSharePoster="appTypeStr === 'mini'"
        >
            <template slot="createDom">
                <!-- 需要绘制的dom 写这 -->
                <div class="share-poster-cont">
                    <div class="share-user">
                        <img v-if="headImg" :src="headImg" alt="">
                        <div>
                            <div class="use-header-name ellipsis">{{ nickName }}</div>
                            <div class="use-header-text" style="width: 13em;text-align: justify">
                               {{posterInfo.date}}我在超鹿
                            </div>
                        </div>
                    </div>
                    <div class="poster-data">
                        <div>{{ posterInfo.venueTimes || 0}}</div>
                        <p>在馆时长（min）</p>
                    </div>
                    <div class="poster-data">
                        <div>{{ posterInfo.kcal || 0}}</div>
                        <p>运动消耗（kcal）</p>
                    </div>
                    <div class="poster-data">
                        <div>{{ posterInfo.courseTimes || 0}}</div>
                        <p>课程时长（min）</p>
                    </div>
                    <div class="poster-code-cont">
                        <img v-if="userPoster" class="poster-code-img" :src="'data:image/png;base64,' + userPoster" alt="">
                    </div>
                    <img class="share-bg" src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/new-people-2022/poster_bg.png" alt="">
                </div>
            </template>
        </common-share>

        <!-- 微信、小程序右上角分享提示 -->
        <van-overlay :show="isShareWx" :z-index="1500" @click="closeShareBox()">
            <div class="tip-box" @click.stop>
                <img src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/anniversary2021/share-mini.png" class="tip-img"/>
            </div>
        </van-overlay>


        <!--规则弹窗-->
        <van-popup v-model="showRule" :overlay="true" :close-on-click-overlay="true" closeable>
            <div class="rule-popup-box">
                <h3>活动规则</h3>
                <div class="rule-popup-text">
                    {{ ruleText }}
                </div>
            </div>
            <!--            <img @click="showRule = false" class="result-close" src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/double11-2021/result-close.png" alt="">-->
        </van-popup>
    </div>
</template>

<script>
import {newAppBack, initBack, gotoAppPage, gotoCoachDetail, appGetDevice, webAppShare, closeWindow, toLoginPage} from '@/lib/appMethod';
import navBar from "@/components/nav-bar/nav-bar";
import appPage from "@/common/components/appPage";
import userMixin from '@/mixin/userMixin';
import wx from 'weixin-js-sdk';
import self from './component/self'
import course from './component/course'
import commonShare from "@/components/commonShare";
import clCityVenue2 from "@/components/cl-city-venue/index3";
import {swiper, swiperSlide} from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import {getParam} from "@/lib/utils";
export default {
    components: {
        navBar,
        appPage,
        swiper,
        swiperSlide,
        clCityVenue2,
        self,
        course,
        commonShare
    },
    mixins: [userMixin],
    data() {
        return {
            showShare: false,
            showReward: false,
            isShareWx: false,
            showRule: false,
            storeShow: false, // 场馆选择
            choseItem: {}, //原生的选择对象
            storeList: [], //原生的选择对象
            initCityList: [], //城市弹窗-数组
            shareItem: ['posterfriend', 'postermessage', 'save'],
            shareParams: {
                title: '送你超鹿新人专属权益，快来体验',
                miniImage: 'https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/new-people-2022/miniImage.png',
                path: `/pages/webView/index?webUrl=${window.location.origin}/#/superdeer-activity/new-people&userId=1&token=1&location=1`,
                miniProgramType:
                    window.location.origin.indexOf('web.chaolu.com') > -1
                        ? '0'
                        : '2', // 正式0，开发1，体验2
                url: 'url',
                multiple: '0.95',
                userName: 'gh_7f5b57b6a76e',
            },
            headImg: '',
            nickName: '',
            userPoster: '',
            swiperOption: {
                autoHeight: true, //enable auto height
                grabCursor: true,
                noSwipingClass : 'no-swiping',
                observer:true,
            },
            venueList: [],
            courseList: [],
            coachList: [],
            campList: [],
            infoList: [{receive: ''}], // 活动信息
            active: 0,
            showType: '0',
            currentCityId: "",
            ruleText: "",
            venueId: ['1'],
            venueName: '中庚青年广场店',
            selfInfo: {}, //自助信息
            courseInfo: {}, //团课信息
            coachInfo: {}, //教练信息
            campInfo: {}, //训练营信息
            boonInfo: {}, //奖励信息
            posterInfo: {}, // 海报信息
            coachId: '', // 新用户评价用
            paramVenueId: '',  // 新用户评价用
            coachHeader: '',  // 新用户评价用
        };
    },
    mounted() {
        document.addEventListener("visibilitychange", () => {
            if (document.visibilityState === "visible") {
                if(this.userId && this.token){
                    this.getActivity(true);
                }
            }else if(document.visibilityState === "hidden"){
                this.showShare = false
                this.isShareWx = false
                this.showReward = false
            }
        })
    },
    methods: {
        toLoginPage,
        newAppBack,
        load() {
            this.countPoint(44, 312, 1512, this.userId)
            this.getCityAndVenue()
            if (this.userId) this.getActivity()
            this.queryCampList()
        },
        queryCoachList() {
            this.$toast.loading({
                message: '加载中...',
                forbidClick: true,
            })
            this.$axios.post(`${this.baseURLApp}/pt/getVenuePerTeacherList`, {
                page: 1,
                rows: 4,
                venueId: this.venueId.length > 0? this.venueId[0] : 1,
                autoVoucher: 0,
                compatible: 1,
                userId: this.userId
            }).then(res => {
                console.log(res)
                this.coachList = res.data
                this.coachList.map(v => {
                    v.labelList = v.labelList? v.labelList.slice(0, 4): []
                    return v
                })
                this.$toast.clear()
            }).catch(r => {
                this.$toast.clear()
            })
        },
        queryCampList() {
            this.$toast.loading({
                message: '加载中...',
                forbidClick: true,
            })
            this.$axios.post(`${this.baseURLApp}/app/newcomerPower/campRecommend`, {cityId: this.currentCityId}).then(res => {
                this.campList = res.data
                this.$toast.clear()
            }).catch(r => {
                this.$toast.clear()
            })
        },
        async getActivity(tag) {
            let deviceId = ''
            try {
                deviceId = await appGetDevice()
            }catch (e){
                // deviceId = 'YekPEv/JI+gDAM2tVNtCsq0S'
                console.log(e)
            }
            console.log('deviceId', deviceId)
            let sourcePlatform = 0
            if(this.appTypeStr === 'and'){
                sourcePlatform = 2
            }else if(this.appTypeStr === 'ios'){
                sourcePlatform = 1
            }else if(this.appTypeStr === 'mini'){
                sourcePlatform = 3
            }
            this.$axios.post(`${this.baseURLApp}/app/newcomerPower/activity`, {
                cityId: this.currentCityId,
                userId: this.userId,
                deviceId: deviceId,
                sourcePlatform
            }).then(res => {
                let data = res.data
                this.showType = data.showType
                this.supportSchedule(data.showType)
                if(data.showType === '1'){
                    this.selfInfo = data.list.find(v=>{return v.giftType === '1'})
                    this.courseInfo = data.list.find(v=>{return v.giftType === '2'})
                    this.coachInfo = data.list.find(v=>{return v.giftType === '3'})
                    this.campInfo = data.list.find(v=>{return v.giftType === '5'})
                    this.ruleText = data.regular
                    this.infoList = [this.selfInfo, this.courseInfo, this.coachInfo]
                    if (this.campInfo) {
                        this.infoList.push(this.campInfo)
                    }
                }
            })
        },
        // 团课助力
        supportSchedule(showType) {
            let inviteUserId = this.$route.query.inviteUserId, userNewcomerPowerRegularId = this.$route.query.taskId
            if(inviteUserId && userNewcomerPowerRegularId){
                this.$axios.post(this.baseURLApp + '/app/newcomerPower/supportSchedule', {
                    userId: this.userId,
                    inviteUserId,
                    userNewcomerPowerRegularId
                }, false).then(res => {
                    console.log('助力成功', res)
                    if(showType === '1'){
                        this.$toast('助力成功！')
                    }else{
                        this.$dialog.alert({
                            message: ' <b>助力成功！</b>',
                            confirmButtonColor: '#267DFF',
                            confirmButtonText: '我知道了',
                            className: 'dialog',
                        }).then(() => {
                            if(this.appTypeStr === 'mini'){
                                wx.miniProgram.switchTab({
                                    url: `/pages/home/index`
                                })
                            }else{
                                this.appBack()
                            }
                        })
                    }
                }).catch((r) => {
                    if(showType === '0'){
                        this.$dialog.alert({
                            message: ` <b>${r}</b>`,
                            confirmButtonColor: '#267DFF',
                            confirmButtonText: '我知道了',
                            className: 'dialog',
                        }).then(() => {
                            if(this.appTypeStr === 'mini'){
                                wx.miniProgram.switchTab({
                                    url: `/pages/home/index`
                                })
                            }else{
                                this.appBack()
                            }
                        })
                    }else{
                        console.log(r)
                        this.$toast(r)
                    }
                })
            }else if(showType === '0' || showType === '3'){
                this.$dialog.alert({
                    message: ' <b>您已经是超鹿的老朋友了<br/>去看看其他活动吧</b>',
                    confirmButtonColor: '#267DFF',
                    confirmButtonText: '我知道了',
                    className: 'dialog',
                }).then(() => {
                    if(this.appTypeStr === 'mini'){
                        wx.miniProgram.switchTab({
                            url: `/pages/home/index`
                        })
                    }else{
                        this.appBack()
                    }
                })
            }
        },
        getCardInfo() {
            let userNewcomerPowerRegularId = '', receive
            if(this.active === 0){
                userNewcomerPowerRegularId = this.selfInfo.userNewcomerPowerRegularId
                receive = this.selfInfo.receive
                if(receive === '1'){
                    if(this.appTypeStr === 'mini'){
                        wx.miniProgram.switchTab({
                            url: `/pages/home/index`
                        })
                    }else{
                        closeWindow()
                    }
                    return false
                }
            }else if(this.active === 1){
                userNewcomerPowerRegularId = this.courseInfo.userNewcomerPowerRegularId
                receive = this.courseInfo.receive
                if(receive === '1'){
                    gotoAppPage(3)
                    return false
                }
            }else if(this.active === 2){
                userNewcomerPowerRegularId = this.coachInfo.userNewcomerPowerRegularId
                receive = this.coachInfo.receive
                if(receive === '1'){
                    gotoAppPage(1, this.venueId.length > 0? this.venueId[0] : 1)
                    return false
                }
            }else if(this.active === 3){
                userNewcomerPowerRegularId = this.campInfo.userNewcomerPowerRegularId
                receive = this.campInfo.receive
                if(receive === '1'){
                    gotoAppPage(8)
                    return false
                }
            }else{
                return false
            }
            if(receive !== '0'){
                return false
            }
            this.countPoint(44, 312, 1513, this.userId)
            this.$axios.post(`${this.baseURLApp}/app/newcomerPower/card`, {
                userNewcomerPowerRegularId: userNewcomerPowerRegularId,
                userId: this.userId,
                token: this.token
            }).then(res => {
                this.boonInfo = res.data
                this.showReward = true
            })
        },
        directlyPopup() {
            let userNewcomerPowerRegularId = this.$route.query.giftId
            if(!userNewcomerPowerRegularId || userNewcomerPowerRegularId === 'undefined'){
                return false
            }
            if(this.$route.query.giftType && this.$route.query.giftType !== 'undefined'){
                switch (this.$route.query.giftType) {
                    case '5':
                        this.active = 3
                        break;
                    case '2':
                        this.active = 1
                        break;
                    case '3':
                        this.active = 2
                        break;
                    default:
                        this.active = 0
                        break;
                }
            }

            this.$axios.post(`${this.baseURLApp}/app/newcomerPower/card`, {
                userNewcomerPowerRegularId: userNewcomerPowerRegularId,
                userId: this.userId,
                token: this.token
            }).then(res => {
                this.boonInfo = res.data
                this.showReward = true
            })
        },
        write(id) {
            this.$router.push(`/superdeer-activity/flag-202201/page-1?userNewcomerPowerRegularId=${id}`)
        },
        unlock(giftType){
            let userNewcomerPowerRegularId = ''
            if(giftType === '1'){
                userNewcomerPowerRegularId = this.selfInfo.userNewcomerPowerRegularId
            }else if(giftType === '2'){
                userNewcomerPowerRegularId = this.courseInfo.userNewcomerPowerRegularId
            }else if(giftType === '3'){
                userNewcomerPowerRegularId = this.coachInfo.userNewcomerPowerRegularId
            }else if(giftType === '5'){
                userNewcomerPowerRegularId = this.campInfo.userNewcomerPowerRegularId
            }else{
                return false
            }
            this.countPoint(44, 312, 1515, this.userId)
            this.$axios.post(`${this.baseURLApp}/app/newcomerPower/directlyUnlock`, {
                userNewcomerPowerRegularId: userNewcomerPowerRegularId,
                userId: this.userId,
                token: this.token
            }).then(res => {
                this.getActivity()
                this.showReward = false
                this.$toast('解锁成功！')
            })
        },
        unlockCamp() {
            this.$axios.post(this.baseURLApp + '/app/newcomerPower/unLockCampGift', {userId: this.userId, userNewcomerPowerRegularId: this.campInfo.userNewcomerPowerRegularId}).then(() => {
                this.getActivity()
                this.showReward = false
                this.$toast('解锁成功！')
            })
        },
        share(giftType){
            this.countPoint(44, 312, 1514, this.userId)
            if(giftType === '1'){
                this.$axios.post(`${this.baseURLApp}/app/newcomerPower/shareTicket`, {
                    userNewcomerPowerRegularId: this.selfInfo.userNewcomerPowerRegularId,
                    userId: this.userId,
                    token: this.token
                }).then(res => {
                    this.posterInfo = res.data || {}
                   this.sharePoster()
                })
            }else if(giftType === '2'){
                this.shareMini()
            }else{
                return false
            }
        },
        sharePoster() {
            this.$axios.post(`${this.baseURLApp}/qrCode/createOrdinaryCode`, {
                userId: this.userId,
                token: this.token,
                width: 240,
                height: 240,
                webUrl: "https://web.chaolu.com.cn/#/ordinary_004001",
                scene: JSON.stringify({webUrl: window.location.origin+'/#/superdeer-activity/new-people', location: '1'})
            }).then(res => {
                this.userPoster = res.data.qrCode
                this.nickName = res.data.nickName
                this.headImg = res.data.headImg
                this.showReward = false
                this.showShare = true
            })
        },
        shareMini(){
            if(this.appTypeStr === 'mini'){
                wx.miniProgram.postMessage({
                    data: {
                        type: 'share',
                        shareImage: this.shareParams.miniImage,
                        shareTitle: '点击助力，帮我领取新人专属团课券',
                        shareUrl: this.shareParams.path + `&inviteUserId=${this.userId}&taskId=${this.courseInfo.userNewcomerPowerRegularId}`,
                    }
                });
                this.isShareWx = true
                this.showShare = false
                this.showReward = false
            }else{
                let miniParams = JSON.parse(JSON.stringify(this.shareParams))
                miniParams.image = miniParams.miniImage
                miniParams.title = '点击助力，帮我领取新人专属团课券'
                miniParams.path = miniParams.path  + `&inviteUserId=${this.userId}&taskId=${this.courseInfo.userNewcomerPowerRegularId}`
                webAppShare('3', miniParams)
            }
        },
        closeShareBox(){
            this.isShareWx = false
            wx.miniProgram.postMessage({
                data: {
                    type: 'share',
                    shareImage: this.shareParams.miniImage,
                    shareTitle: this.shareParams.title,
                    shareUrl: this.shareParams.path,
                }
            });
        },
        shareSuccess() {
            this.showShare = false
        },
        slideChange(e) {
            this.active = this.$refs.mySwiper.swiper.activeIndex
        },
        tabChange(v) {
            this.active = v
            this.$refs.mySwiper.swiper.slideTo(v, 1000, true)
        },
        // 获取城市门店
        getCityAndVenue() {
            this.$axios.post(this.baseURLApp + '/app/sys/area/lessionh5/top').then(res => {
                if (res.code == 1) {
                    this.cityAndVenue = []
                    this.cityList = res.data[0].children
                    this.initCityList = JSON.parse(JSON.stringify(res.data[0].children))
                    this.setCityAndStore()
                }
            })
        },
        getStoreList() {
            this.$axios.post(this.baseURLApp + '/indoorhome/venue/list', {
                "userId": this.userId,
                "cityId": this.currentCityId,
                lng: this.local?.lng ?? null,
                lat: this.local?.lat ?? null,
                page: "1",
                rows: "4",
                "areaType": "1",
            }).then((res) => {
                this.venueList = res.data || []
                this.choseItem.venueId = [this.venueList[0]?.id]
                this.venueId =  [this.venueList[0]?.id]
                this.venueName = this.venueList[0]?.title
                this.queryCoachList()
            })
        },
        setCityAndStore() {
            if (this.currentCityId) {
                let _province = this.cityList.find(x => {
                    return x.children.some(y => y.id === this.currentCityId)
                })
                this.choseItem.cityId = this.currentCityId
                this.choseItem.provinceId = _province.id
                this.cityList.forEach((value, index) => {
                    let _result = value.children.find(y => y.id === this.currentCityId)
                    if (_result) {
                        this.storeList = _result.children
                    }
                })
            }
        },
        // 跳转教练
        jumpCoachList() {
            gotoAppPage(1, this.venueId.length > 0? this.venueId[0] : 1)
        },
        // 跳转教练
        jumpCoachDetail(v) {
            gotoCoachDetail(v)
        },
        jumpEvaluate() {
            this.$dialog.close()
            this.$router.push(`/user-evaluate?teacherId=${this.coachId}&venueId=${this.paramVenueId}&from=new-people&userId=${this.userId}&token=${this.token}`)
        },
        // 跳转训练营
        jumpCamp(v) {
            gotoAppPage(8)
        },
        //门店弹出窗口返回的数据
        choseStorePop(val, param, choseName) {
            this.storeShow = false
            this.storeTotal = choseName || this.storeTotal
            if (param) {
                let {
                    areaId,
                    venueId,
                    areaName,
                    venueName
                } = param
                this.areaId = areaId
                this.venueId = venueId || []
                this.choseItem.venueId = venueId || []
                this.choseItem.areaId = areaId
                this.areaName = areaName
                this.venueName = venueName
                console.log(param)
                // this.setCityAndStore()
                this.queryCoachList()
            }
        },
        customer() {
            gotoCustomerService('训练营专题页')
        },
        dealUrl(v){
            if (!v || v.indexOf('http') < 0) {
                return ('http://img.chaolu.com.cn'+v);
            } else {
                return v;
            }
        },
        // 处理图片加载失败时间
        errorFun(e){
            try {
                e.target.src = 'https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/WeChat/coach/logo.jpg'
            }catch (e){
                console.log('图片处理报错')
            }
        },
        getTeacherDetail() {
            this.$axios.post(this.baseURLApp + '/teacher/getTeacherDetail', {
                teacherId: this.coachId
            }).then((res) => {
                this.coachHeader = res.data.headImg || ''
            })
        },
        // 从教练评价进入
        scanTeacherQrcode() {
            this.$axios.post(this.baseURLApp + '/indoorEvaluation/app/scanTeacherQrcode', {
                teacherId: this.coachId,
                userId: this.userId,
                venueId: this.paramVenueId
            }).then((res) => {
                console.log(res)
            }).catch(e => {
                console.log(e)
            })
        },
    },
    async created() {
        initBack();

        await this.$getAllInfo(['userId', 'token', 'cityId'])
        let cityId = this.$route.query.cityId
        this.coachId = getParam().teacherId || ''
        this.paramVenueId = getParam().venueId || ''

        this.currentCityId = this.cityId ? this.cityId : cityId ? cityId : '3'
        this.load()
        this.getStoreList()
        this.directlyPopup()
        if (this.coachId){
            this.scanTeacherQrcode()
            this.getTeacherDetail()
        }

    },
};
</script>

<style scoped lang="less">
.page-rooter-fff {
    background: #FDEDC7;
    min-height: 100vh;
}
.tab{
    width: 686px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 48px 32px 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    div{

        background: url("https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/new-people-2022/nav1.png") no-repeat center center;
        background-size: cover;
        width: 208px;
        height: 160px;
        border-radius: 12px;
        box-sizing: border-box;
        border: 2px solid #FDEDC7;
        position: relative;
        padding: 12px 16px 4px 16px;
        color: #3C454E;
        font-size: 24px;
        font-weight: bold;
        line-height: 34px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin: 0 12px 0 0;
        //background-color: #FDEDC7;
        &>p{
            color: #6C727A;
            font-size: 16px;
            line-height: 24px;
            font-weight: normal;
        }
        img{
            width: 60px;
            height: 60px;
            position: absolute;
            left: 70px;
            bottom: 52px;
        }
        &.active{
            border-color: #FF6E00;
            box-shadow: 0 0 14px 2px rgba(255, 136, 61, 0.4);
        }
        &:nth-child(2){
            background-image: url("https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/new-people-2022/nav2.png");
        }
        &:nth-child(3){
            background-image: url("https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/new-people-2022/nav3.png");
        }
    }
}
.swiper {
    width: 100vw;
    box-sizing: border-box;
    .swiper-slide {
        width: 100%;
        background: #FDEDC7;
        padding: 32px;
        box-sizing: border-box;
        .swiper-content{
            padding: 28px;
            box-sizing: border-box;
            width: 100%;
            background-color: white;
            border-radius: 16px;
            position: relative;
            // &:after{
            //     content: '';
            //     width: 21px;
            //     height: 21px;
            //     background-color: white;
            //     position: absolute;
            //     top: -10px;
            //     left: 94px;
            //     transform: rotateZ(45deg);
            // }
            .self-img{
                margin-bottom: 60px;
                img{
                    width: 100%;
                    border-radius: 16px;
                }
            }
            &.coach{
                &:after{
                    left: 560px;
                }
            }
            .coach-box{
                .coach-title{
                    color: #242831;
                    font-weight: bold;
                    font-size: 32px;
                }
                .select-venue{
                    padding: 40px 0;
                    &>div:first-child{
                        display: flex;
                        align-items: center;
                        color: #242831;
                        font-weight: bold;
                        font-size: 28px;
                        img{
                            width: 28px;
                            height: 28px;
                            margin-left: 8px;
                            transition: transform 0.5s;
                        }
                    }
                    &>div.active{
                        img{
                            transform: rotateZ(180deg);
                        }
                    }
                    &>div:last-child{
                        padding-right: 26px;
                        color: #6C727A;
                        font-weight: normal;
                        font-size: 24px;
                        background: url("https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/WeChat/defaultPic/right_gray.png") no-repeat right center;
                        background-size: 20px 20px;
                    }
                }
                .coach-list{
                    display: flex;
                    overflow-y: auto;
                    &::-webkit-scrollbar{
                        display: block;
                    }
                    .coach-item{
                        flex-shrink: 0;
                        width: 264px;
                        background: #F9FAFB;
                        border-radius: 16px;
                        overflow: hidden;
                        padding-bottom: 30px;
                        &.coach-item + .coach-item{
                            margin-left: 16px;
                        }
                        img{
                            width: 264px;
                            height: 248px;
                            display: block;
                            object-fit: cover;
                        }
                        .coach-name{
                            display: flex;
                            align-items: center;
                            padding: 0 16px;
                            margin-top: 24px;
                            div{
                                font-size: 28px;
                                font-weight: bold;
                                color: #242831;
                                line-height: 28px;
                            }
                            span{
                                width: 42px;
                                height: 28px;
                                background: #232733;
                                border-radius: 4px;
                                font-size: 16px;
                                color: #FFFFFF;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin-left: 12px;
                            }
                        }
                        .coach-tab{
                            padding: 0 16px;
                            display: flex;
                            align-items: center;
                            margin-top: 24px;
                            span{
                                height: 32px;
                                width: 52px;
                                flex-grow: 0;
                                flex-shrink: 0;
                                background: #EEEEEE;
                                border-radius: 4px;
                                font-size: 18px;
                                color: #3C454E;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin-left: 8px;
                                &:first-child{
                                    margin-left: 0;
                                }
                            }
                        }
                        .coach-num{
                            padding: 0 16px;
                            color: #3C454E;
                            line-height: 18px;
                            font-size: 18px;
                            margin-top: 24px;
                        }
                        .coach-price{
                            margin-top: 14px;
                            padding: 0 16px;
                            display: flex;
                            align-items: flex-end;
                            div{
                                flex-shrink: 0;
                                position: relative;
                                top: 6px;
                                .s1, .s2, .s3{
                                    font-size: 22px;
                                    color: #ED5C43;
                                    font-weight: bold;
                                    font-family: BebasNeueBold;
                                }
                                .s2{
                                    font-size: 40px;
                                }
                            }
                            p{
                                margin-left: 8px;
                                height: 32px;
                                border-radius: 4px;
                                border: 1px solid #267DFF;
                                color: #267DFF;
                                font-weight: bold;
                                font-size: 18px;
                                flex-grow: 0;
                                padding: 0 6px;
                                text-align: center;
                                line-height: 30px;
                                box-sizing: border-box;
                            }

                        }
                    }

                }
                .camp-list {
                    .camp-item {
                        margin: 48px 0 0;
                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
.example-3d{
    margin-bottom: 104px;
    margin-bottom: calc(104px + env(safe-area-inset-bottom));
}
.bottom-btn{
    width: 750px;
    height: 104px;
    background: linear-gradient(237deg, #FFF100 0%, #FFDE00 100%);
    position: fixed;
    bottom: 0;
    left: 0;
    padding-bottom: env(safe-area-inset-bottom);
    z-index: 99;
    color: #242831;
    font-size: 32px;
    font-weight: bold;
    &.disabled{
        filter: grayscale(100%);
        color: #9aa1a9;
    }
}
.reward-box-o{
    position: relative;
    padding:94px 70px 0;
    width: 722px;
    box-sizing: border-box;
    overflow: hidden;
}
.reward-box{
    width: 582px;
    height: 794px;
    background: url("https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/new-people-2022/popup_bg.png") no-repeat center center;
    background-size: 100%;
    padding: 202px 0 0;
    box-sizing: border-box;
    position: relative;
    .popup-icon{
        width: 220px;
        height: 220px;
        position: absolute;
        right: -94px;
        top: -94px;
    }
    .reward-tips{
        white-space: pre-line;
        text-align: center;
        font-size: 28px;
        color: #FFFFFF;
        line-height: 42px;
        font-weight: 500;
    }
    .boon-box{
        display: flex;
        height: 216px;
        width: 498px;
        margin: 46px auto 0;
        align-items: center;
        &>div{
            display: flex;
            justify-content: center;
        }
        &>div:first-child{
            width: 178px;
            flex-grow: 0;
            flex-shrink: 0;
            div{
                color: #ED5C42;
                font-family: BebasNeueBold;
                font-weight: bold;
                display: flex;
                align-items: flex-end;
                position: relative;
                top: 12px;
                span{
                    font-size: 40px;
                    position: relative;
                    top: -6px;
                }
                p{
                    font-size: 112px;
                    line-height: 0.8;
                }
            }
        }
        &>div:last-child{
            flex-grow: 1;
            flex-direction: column;
            padding-left: 32px;
            div{
                line-height: 44px;
                color: #3C454E;
                font-weight: bold;
                font-size: 32px;
            }
            p{
                margin-top: 12px;
                font-size: 24px;
                line-height: 24px;
                color: #6C727A;
            }
        }
    }
    .boon-btn{
        position: absolute;
        bottom: 48px;
        left: 32px;
        width: 518px;
        &>div{
            display: flex;
            justify-content: space-between;
        }
        .img1{
            width: 208px;
            height: 120px;
        }
        .img2{
            width: 322px;
            height: 120px;
        }
        .img-big{
            width: 518px;
            height: 120px;
        }
        .directlyNum{
            position: absolute;
            right: 0;
            top: -58px;
            width: 296px;
            height: 40px;
            background: #FF6E00;
            border-radius: 29px;
            color: #FFFFFF;
            font-size: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            &:after{
                content: '';
                width: 10px;
                height: 10px;
                position: absolute;
                bottom: -5px;
                left: 100px;
                background: #FF6E00;
                transform: rotate(45deg);
            }
        }
    }
}
.result-close{
    width: 60px;
    height: 60px;
    margin: 40px auto 0;
    display: block;
}


.card-item{
    width: 100%;
    height: 600px;
    background-color: #FFDE00;
}

.swipe-item-img {
    height: 420px;
    display: block;
}

.my-swipe {
    position: relative;
    img{
        width: 100%;
    }
    .customer {
        width: 112px;
        height: 48px;
        position: absolute;
        right: 0;
        top: 240px;
    }
}

.page-h {
    img {
        width: 750px;
    }
}

/deep/ .van-swipe__indicator--active {
    width: 24px !important;
    height: 8px !important;
    background: #FFDE00;
    border-radius: 5px;
}

/deep/ .van-swipe__indicator {
    width: 8px;
    height: 8px;
    background: #EEEEEE;
    opacity: 1;
}


// 选择城市门店弹窗
.city-select-box {
    height: 3rem;
    width: 100%;
    //padding-top: 1.0625rem;
    color: #6C727A;
    font-size: 0.875rem;
    display: flex;
    box-sizing: border-box;
    line-height: 0.875rem;
    align-items: center;
}

.icon-down {
    display: inline-block;
    width: 0.75rem;
    //vertical-align: middle;
    margin-left: 0.25rem;
}

.vam {
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #3C454E;
    line-height: 1;
}

.vam-store {
    font-size: 24px;
}

.bg-white {
    background: #fff;
}

.flex, .flex-between {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.flex-between {
    justify-content: space-between;
}

.empty {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    flex-direction: column;

    img {
        width: 320px;
        height: 320px;
    }

    div {
        font-size: 28px;
        color: #6c727a;
        line-height: 44px;
        margin-top: 16px;
    }
}

// 海报样式
.share-poster-cont {
    position: relative;
    overflow: hidden;
    width: 574px;
    height: 818px;
    margin: 0 auto;
    box-sizing: border-box;
    border-radius: 8px;
    img.share-bg{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: block;
        z-index: 1;
    }
}
.share-user{
    display: flex;
    align-items: center;
    position: relative;
    z-index: 99;
    padding: 28px 0 0 24px;
    img{
        width: 52px;
        height: 52px;
        border-radius: 50%;
        margin-right: 12px;
    }
    .use-header-name{
        font-size: 22px;
        //font-weight: bold;
        color: #fff;
        line-height: 22px;
        //width: 45 * @w;
        width: 10em;
    }
    .use-header-text{
        font-size:22px;
        font-weight: 400;
        color: #BABBBB;
        line-height: 22px;
        margin-top: 8px;
    }
}
.poster-data{
    padding-left: 24px;
    margin-top: 60px;
    position: relative;
    z-index: 99;
    div{
        line-height: 84px;
        color: #FFFFFF;
        font-size: 84px;
        font-family: PuHuiTi;
    }
    p{
        margin-top: 6px;
        font-size: 24px;
        line-height: 24px;
        color: #EEEEEE;
    }
}
.share-poster-cont .poster-code-cont {
    z-index: 99;
    position: absolute;
    right: 24px;
    bottom: 48px;
    width: 140px;
    height: 140px;
    padding: 0;
    background: #fff;
    box-sizing: border-box;
    border-radius: 6px;
}

.share-poster-cont .poster-code-img {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    display: block;
    background-color: white;
    box-sizing: border-box;

}
.tip-box {
    position: relative;
    .tip-img {
        position: absolute;
        top: 7px;
        right: 130px;
        width: 300px;
        z-index: 99999;
        display: block;
    }
}
.rule-popup-box{
    width: 654px;
    box-sizing: border-box;
    padding-bottom: 24px;
    h3{
        text-align: center;
        padding: 32px 0;
    }
    .rule-popup-text{
        width: 100%;
        //min-height: 400px;
        max-height: 650px;
        display: block;
        padding: 0 48px;
        box-sizing: border-box;
        overflow-y: auto;
        font-size: 24px;
        font-weight: 400;
        color: #666666;
        line-height: 40px;
        white-space: pre-line;
    }
}
.coach-header{
    position: fixed;
    right: 32px;
    top: 520px;
    z-index: 9999;
    img{
        width: 128px;
        height: 128px;
        background-color: white;
        padding: 5px;
        border: 3px solid #E9AA42;
        box-sizing: border-box;
        border-radius: 50%;
    }
   p{
       width: 124px;
       height: 44px;
       background-image: linear-gradient(90deg, #FBE9C3 0%, #FFD372 100%);
       color: #6E5936;
       font-weight: 500;
       font-size: 24px;
       line-height: 24px;
       margin-top: -20px;
       border-radius: 22px;
   }
}
</style>
